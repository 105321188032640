import React from "react";
import { FaPlay, FaPause } from "react-icons/fa";
import { MdVolumeUp } from "react-icons/md";

const DummyAudioPlayer = ({ duration }) => {
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  const formattedDuration = formatTime(duration);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "4px",
        border: "1px solid #000000",
        borderRadius: "24px",
        backgroundColor: "#f9f9f9",
        width: "250px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <button
          style={{
            border: "none",
            background: "none",
            fontSize: "12px",
            cursor: "pointer",
            paddingLeft: "20px",
          }}
        >
          <FaPlay />
        </button>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ fontSize: "12px", color: "#666" }}>00:00 / {formattedDuration}</span>
        </div>
      </div>

      <div
        style={{
          flex: 1,
          margin: "0 15px",
          height: "5px",
          backgroundColor: "#ddd",
          borderRadius: "4px",
          position: "relative",
        }}
      ></div>

      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <button
          style={{
            border: "none",
            background: "none",
            fontSize: "18px",
            cursor: "pointer",
            paddingRight: "10px",
          }}
        >
          <MdVolumeUp />
        </button>
      </div>
    </div>
  );
};

export default DummyAudioPlayer;
