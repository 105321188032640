import { useRef } from "react";
import { maskMobileNumber } from "../Functions/mask";
import useAgentStore from "../store/agent/agentStore";

import useAuthStore from "../store/authStore";
import useGeneralStore from "../store/generalStore";
import useCustomSocket from "./useCustomSocket";
import useBorrowerInfo from "../Agent/features/borrower-info/store";

const socketTypes = {
  COMMON_ACTIVITY_ADDED: "common_activity_added",
  PRE_CALL_NOTIFICATION: "pre_call_notification",
  REMOVE_PRE_CALL_NOTIFICATION: "remove_pre_call_notification",
  AUTO_DISPOSITION_ADDED_AFTER_CALL: "auto_disposition_added_after_call",
  CALL_COMPLETED_NOTIFICATION: "call_completed_notification",
  AUTOMATIC_CALL_TRIGGER: "automatic_call_trigger",
  CAMPAIGN_CALL_TRIGGER: "campaign_call_trigger",
  UNKNOWN_CALL_NOTIFICATION: "unknown_call_notification",
};

const typeFilters = Object.values(socketTypes);

export default function useAgent() {
  const userData = useAuthStore((state) => state.userData);
  const showAlert = useGeneralStore((state) => state.open_alert);
  const openBorrowerInfo = useBorrowerInfo((state) => state.openBorrowerInfo);

  const isFetched = useRef(false);

  const addIncomingCallData = useAgentStore((state) => state.addIncomingCallData);
  const removeIncomingCallData = useAgentStore((state) => state.removeIncomingCallData);
  const showIncomingCall = useAgentStore((state) => state.showIncomingCall);

  const toggleCallCommonActivity = useAgentStore((state) => state.toggleCallCommonActivity);

  const addCustomerId = useAgentStore((state) => state.addCustomerId);

  const { status } = useCustomSocket({
    typeFilters,
    onMessage: (json) => {
      if (json.type === socketTypes.COMMON_ACTIVITY_ADDED && json.fetch === true) {
        if (!!isFetched.current) return;

        isFetched.current = true;
        const timoutInstance = setTimeout(() => {
          toggleCallCommonActivity();
          isFetched.current = false;
          clearTimeout(timoutInstance);
        }, 60 * 1000);
      }

      if (json.type === socketTypes.PRE_CALL_NOTIFICATION) {
        let data = json.data;
        showAlert({
          variant: data?.variant === "yellow" ? "warning" : data?.variant === "red" ? "danger" : data?.variant === "green" ? "success" : "info",
          msg: (
            <span style={{ cursor: "pointer" }} onClick={() => openBorrowerInfo(data?.customer_id)}>
              {data?.message}
            </span>
          ),
          persist: true,
        });

        addIncomingCallData(data);
        showIncomingCall();
      }

      if (json.type === socketTypes.UNKNOWN_CALL_NOTIFICATION) {
        let data = json.data;
        showAlert({
          variant: data?.variant === "yellow" ? "warning" : data?.variant === "red" ? "danger" : data?.variant === "green" ? "success" : "info",
          msg: <span style={{ cursor: "pointer" }}>{data?.message}</span>,
          persist: true,
        });

        // addIncomingCallData(data);
        // showIncomingCall();
      }

      if (json.type === socketTypes.REMOVE_PRE_CALL_NOTIFICATION) {
        let data = json.data;
        removeIncomingCallData({ customer_number: data?.customer_number });
      }

      if (json.type === socketTypes.AUTO_DISPOSITION_ADDED_AFTER_CALL) {
        showAlert({
          variant: json?.variant === "yellow" ? "warning" : json?.variant === "red" ? "danger" : json?.variant === "green" ? "success" : "info",
          msg: json?.message,
        });
      }

      if (json.type === socketTypes.CALL_COMPLETED_NOTIFICATION) {
        let data = json.data;
        showAlert({
          variant: data?.variant === "yellow" ? "warning" : data?.variant === "red" ? "danger" : data?.variant === "green" ? "success" : "info",
          msg: (
            <span style={{ cursor: "pointer" }} onClick={() => openBorrowerInfo(data?.customer_id)}>
              {data?.message}
            </span>
          ),
          persist: true,
        });
        // addIncomingCallData(data);
        // showIncomingCall();
      }

      if (json.type === socketTypes.AUTOMATIC_CALL_TRIGGER) {
        if (!userData) return;
        let data = json.data;
        showAlert({
          variant: "info",
          msg: "Automatic call has been initiated to " + (!!userData?.remove_masking ? data?.customer_number : maskMobileNumber(data?.customer_number)),
        });
      }
      if (json.type === socketTypes.CAMPAIGN_CALL_TRIGGER) {
        if (!userData) return;
        let data = json.data;
        showAlert({
          variant: "info",
          msg: "Campaign call has been initiated to " + (!!userData?.remove_masking ? data?.customer_number : maskMobileNumber(data?.customer_number)),
        });
      }
    },
  });

  console.log("Socket : ", status);

  return null;
}
