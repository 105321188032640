import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import ReactAudioPlayer from "react-audio-player";

const FloatingAudioPlayer = ({ audioUrl, onClose }) => {
  const [audioSrc, setAudioSrc] = useState(null);

  useEffect(() => {
    const fetchAudio = async () => {
      try {
        if (!audioUrl) {
          throw new Error("No audio URL provided.");
        }

        const apiUrl = "https://api.themedius.ai/dashboard/api/download_exotel_call_recording/";

        const response = await fetch(`${apiUrl}?url=${audioUrl}`, {
          method: "GET",
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch audio: ${response}`);
        }

        const audioBlob = await response.blob();
        const audioObjectURL = URL.createObjectURL(audioBlob);

        setAudioSrc(audioObjectURL);
      } catch (err) {
        console.log("Got Error : ", err.message);
      }
    };

    fetchAudio();

    return () => {
      if (audioSrc) {
        URL.revokeObjectURL(audioSrc);
      }
    };
  }, [audioUrl]);

  return (
    <div
      style={{
        position: "fixed",
        bottom: "50%",
        left: "50%",
        transform: "translate(-50%, 50%)",
        width: "600px",
        height: "150px",
        padding: "15px",
        backgroundColor: "#fff",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        zIndex: 1000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <button
        onClick={onClose}
        style={{
          position: "absolute",
          top: "5px",
          right: "10px",
          background: "none",
          border: "none",
          fontSize: "16px",
          cursor: "pointer",
        }}
      >
        ✖
      </button>
      <ReactAudioPlayer src={audioSrc} controls autoPlay={false} style={{ width: "100%" }} />

      {audioSrc && (
        <Button
          href={audioSrc}
          download="call-recording.mp3" 
          style={{
            marginTop: "10px",
            padding: "10px 20px",
            backgroundColor: "#DADBDD",
            color: "black",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            textDecoration: "none",
          }}
        >
          Download Audio
        </Button>
      )}
    </div>
  );
};

export default FloatingAudioPlayer;
