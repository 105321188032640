import React, { useState, useEffect, useMemo } from "react";
import ReactAudioPlayer from "react-audio-player";
import EllipseVerticleIcon from "../../../Static/RawImages/ellipsis-vertical-icon.svg";
import NoRecord from "../../../Components/NoRecord";
import ContentLoader from "../../../Components/ContentLoader";
import { fetchAgentCallDone } from "../../../API/agentApis";
import useAuthStore from "../../../store/authStore";
import useGeneralStore from "../../../store/generalStore";
import Paging from "../../../Components/Paging";
import DatePicker from "../../../ui/forms/DatePicker";
import CallButton from "../../Components/CallButton";
import WhatsappButton from "../../Components/WhatsappButton";
import PendingDispAddDispForm from "../../Components/pendingDispositions/AddDispForm";
import useForm from "../../../hooks/useForm";
import { format } from "date-fns";
import BorrowerSearch from "../../Components/BorrowerSearch";
import CustomerDetails from "../../Components/CustomerDetails";
import IncomingCall from "../../Components/IncomingCall";
import useAgentStore from "../../../store/agent/agentStore";
import { commaFormat } from "../../../Functions/formatNumber";
import DummyAudioPlayer from "../../Components/pendingDispositions/DummyAudioPlayer";
import FloatingAudioPlayer from "../../Components/pendingDispositions/CallRecordingPlayer";
const LIMIT_PER_PAGE = 20;

function PendingDisp() {
  const userData = useAuthStore((state) => state.userData);
  const showAlert = useGeneralStore((state) => state.open_alert);
  const toggleLeadCountFetchFlag = useAgentStore((state) => state.toggleLeadCountFetchFlag);
  const reloadFlag = useAgentStore((state) => state.reload_now);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showAddDispModal, setShowAddDispModal] = useState(false);
  const [selectedDisp, setSelectedDisp] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageTotal, setPageTotal] = useState("NaN");
  const [CallRecordingIndex, SetCallRecordingIndex] = useState(null);

  const [filters, handleFilters, resetFilters] = useForm({
    sort_by: "",
    borrower: "",
    date: null,
    client_id: "",
  });

  const handlePagination = (type, target) => {
    if (type === "directValue") setCurrentPage(parseInt(target));
  };

  const fetchData = async () => {
    setLoading(true);

    const res = await fetchAgentCallDone({
      user_id: userData?.user_id,
      page: currentPage,
      ...filters,
      sort_by: !!filters?.sort_by ? filters?.sort_by : null,
      date: !!filters?.date ? format(new Date(filters?.date), "yyyy-MM-dd") : null,
    });

    if (res) {
      setPageTotal(Math.ceil(res?.total / LIMIT_PER_PAGE));
      setData(res?.data);
    } else {
      showAlert({ variant: "danger", msg: "Unable to fetch Data" });
    }
    setLoading(false);
    toggleLeadCountFetchFlag();
  };

  const onCloseForm = () => {
    SetCallRecordingIndex(null);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, filters, reloadFlag]);

  useEffect(() => {
    resetFilters();
  }, []);

  const lenderOptions = useMemo(() => {
    const opts = !!userData?.clients?.length ? userData?.clients?.map((el) => ({ label: el?.client_name, value: el?.client_id })) : [];
    return [{ label: "Choose", value: "" }, ...opts];
  }, [userData]);

  return (
    <>
      <div className="pageContent broken-ptp-layout">
        <div className="broken-ptp-content">
          <div className="table-scroll" style={{ height: "100%", minHeight: "30rem" }}>
            <table className="agent-filter-table">
              <thead>
                <tr>
                  <th>
                    <BorrowerSearch value={filters?.borrower} onChange={(val) => handleFilters("borrower", val)} />
                  </th>
                  <th>
                    <div className="filter-label">Date</div>
                    <DatePicker label="Choose" value={filters?.date} onChange={(val) => handleFilters("date", val)} />
                  </th>
                  <th>
                    <div className="filter-label">Total Oustanding</div>
                    <select className="form-select" value={filters?.sort_by} onChange={(e) => handleFilters("sort_by", e.target.value)}>
                      {[
                        { label: "Choose", value: "" },
                        { label: "Highest to Lowest", value: "desc" },
                        { label: "Lowest to Highest", value: "asc" },
                      ]?.map(({ label, value }, index) => (
                        <option key={index} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </th>
                  <th className="align-top">
                    <div className="filter-label">Lender Name</div>
                    <select className="form-select" value={filters?.client_id} onChange={(e) => handleFilters("client_id", e.target.value)}>
                      {lenderOptions?.map(({ label, value }, index) => (
                        <option key={index} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  !!data?.length &&
                  data?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div className="item-col">
                          <div className="item-text item-name">
                            <a>{item?.customer?.customer_name || "----"}</a>
                          </div>
                          <div className="item-state item-label">
                            {item?.state || "----"} ({item?.city || "----"})
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="item-col date-format">
                          <div className="item-text">{!!item?.source_of_lead_datetime ? item?.source_of_lead_datetime?.split(" | ")?.[0] : ""}</div>
                          <div className="item-label">
                            {" "}
                            {!!item?.source_of_lead_datetime ? item?.source_of_lead_datetime?.split(" | ")?.[1]?.slice(0, -3) : ""}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="item-col">
                          <div className="item-text item-price">
                            <span className="me-1 tx-16">{`₹`}</span>
                            {commaFormat(item?.customer?.pos)}
                          </div>
                          <div className="bank-name item-label">{item?.client_name || "----"}</div>
                        </div>
                      </td>
                      <td>
                        <div className="item-col item-audio">
                          {console.log(item)}
                          <div className="mt-3 mb-2 w-full " onClick={() => SetCallRecordingIndex(index)}>
                            <DummyAudioPlayer duration={item?.ConversationDuration} />
                          </div>
                          {index === CallRecordingIndex && <FloatingAudioPlayer audioUrl={item?.RecordingUrl} onClose={() => onCloseForm()} />}
                        </div>
                      </td>
                      <td>
                        <div className="item-col item-col-btn">
                          <div className="item-btn">
                            <CallButton customer_mobile_number={item?.To || ""} />
                            <span>{item?.last_call_disposition || "----"}</span>
                          </div>
                          <div className="item-btn">
                            <WhatsappButton whatsapp_no={item?.To} />
                            <span>{item?.last_whatsapp_status || "----"}</span>
                          </div>
                          <div className="dropdown verticle-ellipse">
                            <a className="dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                              <img src={EllipseVerticleIcon} />
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <a
                                  onClick={() => {
                                    setSelectedDisp(item);
                                    setShowAddDispModal(true);
                                  }}
                                  className="dropdown-item"
                                >
                                  Add Disposition
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item">Send Information</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        {!loading && !!data?.length && (
          <div className="pb-3 mt-4">
            <Paging currentPage={currentPage} pageTotal={pageTotal} handlePagination={handlePagination} />
          </div>
        )}
      </div>

      {!loading && !data?.length && <NoRecord />}
      {loading && <ContentLoader />}

      <PendingDispAddDispForm data={selectedDisp} setData={setSelectedDisp} show={showAddDispModal} setShow={setShowAddDispModal} />

      <IncomingCall />
      <CustomerDetails />
    </>
  );
}

export default PendingDisp;
